.App {
  text-align: center;
}
::-webkit-scrollbar {
  display: none;
}

/*.App-logo {
  height: 10vmin;
  pointer-events: none;
  margin-left: 65%;
}

.App-logo2 {
  height: 10vmin;
  pointer-events: none;
  margin-left: 65%;
  margin-bottom: 15%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear reverse;
  }
}*/


/*.App-header {
  background-color: #282c34;
  min-height: 104.9vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /*background-image: url('./carousel/index.png');} 


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
.indeximg{
max-width: 100%;
}
/*-------------------------------------*/

.logoSize{
  height: 10vmin;
  pointer-events: none;
}
.logoSize2{
  height: 53vmin;
  pointer-events: none;
}

.icoSize{
  margin-left: 20px;
}
/*.icoSize > img:hover{
  height: 4.9vmin;
}*/

.hideElem{
  display: none;
}

.colorNav{
  background-color: black;
  color: black;
}

.floatRight{
  float: right!important;
}

.centNav{
  margin-left: 23%;
  font-size: 4vmin!important;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.top-bar{
  padding: 0!important;
  background-color: black;
  color: black;
}
.container > span{
  font-size: 1rem;
  font-weight: 100;
}

.render {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(3px + 2vmin);
  line-height: 25px;
  padding-left: 100px;
  padding-right: 100px;
}

#media{
padding-left: 100px;
}

.justificado > p{
text-align: justify;
}

.myv {
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(3px + 2vmin);
  line-height: 25px;
  padding-left: 100px;
  padding-right: 100px;
}

#mision{
  /*padding-right: 53vh;*/
  margin-right: 45px;
}

#vision{
  /*padding-left: 53vh;*/
  margin-right: 45px;
}

.row {
  margin-right: 0!important;
  margin-left: 0!important;
}

.linkren{
  text-decoration: none!important;
  color: rgba(255,255,255,.5);
  font-size: 1.1rem;
}

.linkren:hover{
  color:rgba(255,88,0,1);
  /*color: rgba(255,255,255,.75);*/
}
#collasible-nav-dropdown:hover {
  color:rgba(255,88,0,1);
}
#responsive-navbar-nav > div > a.linkren.nav-link:hover{
  color:rgba(255,88,0,1);
}
#responsive-navbar-nav > div > div > div > a{
  text-align: center;
}

.catalocard{
  background: none!important;
}

.img-size{
  height: 134px;
}

body > div.fade.modal.show > div > div > div.modal-body > div > div > div > img{
  max-width: 7.5rem;
}
hr{
  margin-top: 0!important;
}
.card-text{
  text-align: justify;
  padding:0 5% 0 5%;
}

@media (min-width: 1200px){
#top-container {
    max-width: 1300px!important;
}
#top-container > span{
  font-size: .8rem;
  color: rgba(255,88,0,1);
  cursor: default;
}
#nav-padding > div{
  max-width: 1186px;
}
#responsive-navbar-nav > div > a, #responsive-navbar-nav > div > div {
  padding-right: 5.3rem!important;
}
#nav-padding{
  padding: 0!important;
  background: linear-gradient(180deg, black, transparent);
  background-color:rgba(52,58,64,.8)!important;
}
/*#responsive-navbar-nav > div > a, #collasible-nav-dropdown{
  color: aliceblue;
}
#responsive-navbar-nav > div > a:hover, #collasible-nav-dropdown:hover{
  color: orange;
}*/
#responsive-navbar-nav > div > div > div{
  background: linear-gradient(360deg, black, transparent);
}
#responsive-navbar-nav > div > div > div > a{
  background-color: transparent;
  color:rgba(255,255,255,.5);
}
#responsive-navbar-nav > div > div > div > a:hover{
  background: black;
  color: rgba(255,255,255,.75);
}
#responsive-navbar-nav > div > div > div > div{
  border-top: 1px solid gray;
}
.card:hover{
  cursor: pointer;
  border: 1px solid gray;
}
.card-body, .card-footer{
  color: #282c34;
}
#productoD{
  background: black;
  margin-bottom: 0px;
  color: white;
  padding-bottom: 10px;
  padding-top: 5px;
}
.card-deck{
  background: linear-gradient(180deg, black, transparent);
}
.d-block{
  height: 105vmin;
}
#productoDest > ol, #root > div > div:nth-child(11) > ol{
  display: none;
}
#productoDest > a.carousel-control-next > span.carousel-control-next-icon,
#productoDest > a.carousel-control-prev > span.carousel-control-prev-icon{
  height: 2rem!important;
  width: 2rem!important;
}
#productoDest > a.carousel-control-next, #productoDest > a.carousel-control-prev{
  background-color: lightgray;
  width: 7%;
}
.card-group>.card:not(:first-child), .card-group>.card:not(:last-child){
cursor: default;
}
.categtre:hover{
text-decoration: none;
}
.categtre > small:hover{
color: rgba(255,88,0,1)!important;
}
.categtrecard:hover{
  cursor: default;
}
/*#productoDest .carousel-control-prev-icon{
width: 20rem!important;
}*/
#root > div > div.justify-content-center.nav{
  background: black;
}
#root > div > div.justify-content-center.nav > div > a{
  color: #404040;
}
#root > div > div.justify-content-center.nav > div > a:hover{
  color: aliceblue;
}
.footerCol{
  padding-top: 5px;
  background-color: #282c34;
  min-height: 35vh;
  font-size: calc(3px + 2vmin);
  color: white;
}
#root > div > footer > div > div:nth-child(1) > a{
  color: white;
  text-decoration: none;
}
#root > div > footer > div > div:nth-child(1) > a:hover{
  color: rgba(255,255,255,.75);
}
#root > div > div.App > div:nth-child(13) > ol,
#root > div > div.App > div:nth-child(13) > a.carousel-control-next,
#root > div > div.App > div:nth-child(13) > a.carousel-control-prev{
  display: none;
}
}
@media (min-width: 576px){
.card-deck {
    margin-right: 0px!important; 
    margin-left: 0px!important;
}
}
@media (max-width: 576px){
  .myv {
    /*min-height: 100vh;*/
    display: block;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(3px + 2vmin);
    line-height: 25px;
    padding-left: 13%;
    padding-right: 1%;
  }
  
  #mision {
    /*padding-right: 53vh;*/
    margin-right: 45px;
  }
  
  #vision {
    /*padding-left: 53vh;*/
    margin-right: 45px;
  }

  #productoDest {
    margin-left: 11%;
  }
  .centNav {
    margin-left: 13%;
  }
  #root > div > nav.colorNav.navbar.navbar-expand-lg.navbar-dark > a{
    margin-left: 33%;
  }
  #root > div > nav.colorNav.navbar.navbar-expand-lg.navbar-dark > span:nth-child(1){
    margin-left: 43%;
  }
  }
  
/*@media (max-width: 1180px) {
  .centNav{
    font-size: 3vmin!important;
  }
}

@media (max-width: 910px) {
  .centNav{
    margin-left: 8%;
  }
}

@media (max-width: 775px) {
  .centNav{
    margin-left: 0;
    font-size: 2vmin!important;
  }
}*/
@media (max-width: 775px) {
  .render {
    display: grid!important;
    }
    #root > div > div.App > header > img:nth-child(4), #root > div > div.App > div.render > div:nth-child(1) > img:nth-child(4){
      width: 200px;
    }
    #media {
      padding-left: 0px;
  }
  #top-container > span:nth-child(1), #top-container > span:nth-child(2), #top-container > span:nth-child(3), #top-container > span:nth-child(4){
    display: none;
  }
  #top-container > span:nth-child(5){
    margin-left: 5%;
  }
  .icoSize {
    margin-left: 40px;
}
  .linkren{
    color: rgba(255,88,0,1);
  }
  #responsive-navbar-nav > div > a.linkren.nav-link{
    color:rgba(255,88,0,1);
  }
  #collasible-nav-dropdown{
    color: rgba(255,88,0,1);
  }
  #root > div > footer > div > div:nth-child(1){
    padding-bottom: 20px;
  }
 }
 /* ****************************************home social-list******************************************************************************* */
.home-social-list {
  display: block;
  position: fixed;
  bottom: 25%;
  right: 5px;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 2rem;
  line-height: 1.75;
  text-align: center;
  -webkit-transform-origin: right top 0;
  -ms-transform-origin: right top 0;
  transform-origin: right top 0;
  float: right;
  -webkit-animation: animate-it 5s ease infinite;
  animation: animate-it 10s ease infinite;
  z-index: 100;
}

.fondo {
  display: inline-block;
  height: 10%;
  width: 57px;
  background-color: rgba(83, 85, 86, 1);
  border-radius: 50%;
  margin-top: 1.5rem;
}

.home-social-list li {
  padding-left: 0;
}

.home-social-list li a,
.home-social-list li a:visited {
  color: rgba(255,88,0,1);
}

.home-social-list li a:hover,
.home-social-list li a:focus,
.home-social-list li a:active {
  color: #151515;
}

#numberph:hover {
  cursor: pointer;
}

.phonebye {
  display: none;
}

.phonehello {
  display: inline;
}

/*.numbermove{
  margin-left: 400px!important;
}*/
.numbermove1 {
  margin-right: -253px !important;
}

#inicio {
  color: var(--limon);
}

@-webkit-keyframes animate-it {

  0%,
  60%,
  80%,
  100% {
      -webkit-transform: translateX(0);
  }

  20% {
      -webkit-transform: translateX(-5px);
  }

  40% {
      -webkit-transform: translateX(100px);
  }
}

@keyframes animate-it {

  0%,
  60%,
  80%,
  100% {
      -webkit-transform: translateX(0);
  }

  20% {
      -webkit-transform: translateX(-5px);
  }

  40% {
      -webkit-transform: translateX(100px);
  }
}

.videosre{
max-width: 98%;
}
@media (max-width: 576px){
  #root > div > div.App > div:nth-child(11) > div > div:nth-child(2) > span,
  #root > div > div.App > div:nth-child(14) > div > div:nth-child(1) > span{
      font-size: small!important;
  }
  #root > div > div.App > div.card > div{
    font-size: x-small!important;
}
  #root > div > div.App > div:nth-child(11) > div > div, 
  #root > div > div.App > div:nth-child(14) > div > div{
    flex-basis: unset;
  }
  /*arreglando las letras del footer en contacto seccion*/
  .centraGoogle {
    padding-bottom: 70%!important;
    /*margin-left: 5%!important;*/
  }
  /*catalogos espacio para el cel*/
  .catalocard {
    margin-left: 10%;
  }
  .modal-body {
    margin-left: 20%;
  }
 .home-social-list {
  bottom: 15%;
  }
  /* barras de el menu de subcategorias el fondo y sus lineas*/
  .navbar-nav .dropdown-menu {
    background-color: #343a40!important;
  }
  .dropdown-divider{ 
    border-top: 1px solid #282c34!important;
  }
}
#contactor > div.centraGoogle > div > div:nth-child(1) > div > div > div > div > div:nth-child(1) > div:nth-child(3) > div > div:nth-child(4) > div > div > div > div > div > div > div > a:nth-child(8){
  text-decoration: underline!important;
}
@media (min-width: 576px){
  #root > div > div:nth-child(4) > div.containcto.container{
  max-width: 30%!important;
  }
  }