#root > div > div.App > div.card{
text-align: justify;
width: 80%;
margin-left: 10%;
}

#root > div > div.App > div.card > div{
font-weight: 500;
}

#root > div > div.App > div.card:hover{
cursor: default;
}
#root > div > div.App > div:nth-child(11) > div > div:nth-child(2) > span,
#root > div > div.App > div:nth-child(14) > div > div:nth-child(1) > span{
    font-weight: 500;
    font-size: xx-large;
}
#root > div > div.App > div:nth-child(11) > div > div:nth-child(2) > div,
#root > div > div.App > div:nth-child(14) > div > div:nth-child(1) > div{
    text-align: justify;
    font-size: 2.65vmin;
    line-height: 27px;
}
#root > div > div.App > div:nth-child(11) > div > div:nth-child(1) > img,
#root > div > div.App > div:nth-child(14) > div > div:nth-child(2) > img,
#root > div > div.App > img{
    max-width: 100%;
    max-height: 100%;
}

#antiestimg{
    border-radius: 50%;
}

#root > div > div.App > div:nth-child(14) > img{
    max-width: 50%;
    max-height: 50%;
    margin-left: 26%;
}

#root > div > div.App > div:nth-child(14){
    border: none;
}

#root > div > div.App > div:nth-child(14) > div > p{
    font-size: 3vmin;
    line-height: 30px;
}

.centraGoogle{
    margin-bottom: 30%;
    margin-left: 24.5%;
}

.mapStyles {
    height: 50%!important;
    width: 50%!important;
}